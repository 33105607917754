import { useEffect } from 'react';

declare global {
  interface Window {
    FrontChat: (
      action: string,
      config: {
        chatId: string;
        useDefaultLauncher: boolean;
        email: string;
        name: string;
        userHash: string;
      },
    ) => void;
  }
}

export type FrontSignatureDetails = {
  frontUserHash: string;
  frontChatId: string;
};

type ChatBotProps = {
  email: string;
  firstName: string;
  frontSignatureDetails: FrontSignatureDetails;
};

const ChatBot = ({ frontSignatureDetails, firstName, email }: ChatBotProps) => {
  const { frontUserHash, frontChatId } = frontSignatureDetails;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
    script.async = true;
    script.onload = () => {
      if (!window.FrontChat) {
        return;
      }
      window.FrontChat('init', {
        chatId: frontChatId,
        useDefaultLauncher: true,
        email,
        name: firstName,
        userHash: frontUserHash,
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [email, firstName, frontChatId, frontUserHash]);

  return null;
};

export default ChatBot;
