import useFeatureFlag, { ActiveFlags } from ':src/domains/feature-flags/hooks/useFeatureFlag';
import React from 'react';

export type FeatureFlaggedComponentProps = {
  flag: ActiveFlags;
  trueComponent: React.ReactNode;
  falseComponent: React.ReactNode;
};

export default function FeatureFlaggedComponent({
  flag,
  trueComponent,
  falseComponent,
}: FeatureFlaggedComponentProps) {
  const isFlagOn = useFeatureFlag(flag);
  return isFlagOn ? <>{trueComponent}</> : <>{falseComponent}</>;
}
