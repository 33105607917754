import { graphql } from ':src/graphql';

export const viewerQuery = graphql(`
  query ViewerQuery {
    viewer {
      id
      scope
      email
      smallAvatar
      firstName
      lastName
      fullName
      displayName
      slug
      signature
      phone
      organization {
        id
        name
      }
      supervisorProfile {
        id
        supervisorBandwidthId
      }
      superviseeProfile {
        id
        licensureUsState {
          id
        }
      }
      isSupervisor
      isSupervisee
      timezone
      featureFlags
      superviseeOnboardingInfo {
        supervisorUserId
      }
      stripeVerified
      openIntroRequestsCount
      openZoomSessionsInApp
      accessRevokedCalendar
      canSuperviseesBookSessions
      isCypress
      frontSignatureDetails {
        frontUserHash
        frontChatId
      }
    }
  }
`);
