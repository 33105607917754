import React from 'react';
import { Box, Breakpoint } from '@mui/material';
import Header from ':src/components/Header';
import { Footer } from '@motivo/guanyin/src/components';
import { useLocation } from 'react-router';
import ChatBot from '@motivo/guanyin/src/components/ChatBot';
import FeatureFlaggedComponent from './components/FeatureFlaggedComponent';
import useViewer from './domains/auth/hooks/useViewer';

export type LayoutProps = {
  children: React.ReactNode;
  hideHeaderLinks?: boolean;
  centerHeaderLogo?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  overflowHidden?: boolean;
  headerMaxWidth?: Breakpoint | false;
};

export default function Layout({
  children,
  hideHeaderLinks = false,
  centerHeaderLogo = false,
  hideFooter = false,
  hideHeader = false,
  overflowHidden = false,
}: LayoutProps) {
  const location = useLocation();
  const isSessionJoinPage = /\/dashboard\/sessions\/\d+\/join/.test(location.pathname);
  const finalHideHeader = hideHeader || isSessionJoinPage;
  const finalHideFooter = hideFooter || isSessionJoinPage;
  const { viewer } = useViewer();
  const { frontSignatureDetails, firstName, email, isSupervisor } = viewer;

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {!finalHideHeader && <Header hideLinks={hideHeaderLinks} centerLogo={centerHeaderLogo} />}
      <Box flex={1} component="main" {...(overflowHidden && { overflow: 'hidden' })}>
        {children}
      </Box>
      {frontSignatureDetails && firstName && email && isSupervisor && (
        <FeatureFlaggedComponent
          flag="chatbot"
          trueComponent={
            <ChatBot
              frontSignatureDetails={frontSignatureDetails}
              firstName={firstName}
              email={email}
            />
          }
          falseComponent={null}
        />
      )}
      {!finalHideFooter && <Footer />}
    </Box>
  );
}
