import useViewer from ':src/domains/auth/hooks/useViewer';

export type ActiveFlags =
  | 'isabelle'
  | 'timestone--aphrodite'
  | 'embedded-zoom-session'
  | 'matilda'
  | 'grinch-heart--aphrodite'
  | 'workos'
  | 'admin-hours--fe-clinical-setup-option'
  | 'chatbot';

export default function useFeatureFlag(flag: ActiveFlags) {
  const { viewer } = useViewer();
  const { featureFlags } = viewer;

  return featureFlags?.includes(flag);
}
