import { ConfigType, setConfig } from './config';
import { signIn, signOut } from './services';

export function initAuth(config: ConfigType) {
  setConfig(config);
}

export function login({
  redirect,
  userId,
  organizationAdminId,
}: {
  redirect?: string;
  userId?: number;
  organizationAdminId?: number;
} = {}) {
  localStorage.setItem(
    'redirectAfterLogin',
    redirect || `${window.location.pathname}${window.location.search}`,
  );
  return signIn({ userId, organizationAdminId });
}

export function clearAuthState() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('motivo_user_id');
  localStorage.removeItem('motivo_organization_admin_id');
  document.cookie = 'wos-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export async function logout(): Promise<void> {
  try {
    const userId = localStorage.getItem('motivo_user_id');
    const organizationAdminId = localStorage.getItem('motivo_organization_admin_id');

    clearAuthState();

    await signOut({ userId, organizationAdminId });
  } catch (e) {
    throw new Error(`[clearAuthState] Error: ${e}`);
  }
}
